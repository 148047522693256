<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      Passagens Vendidas
    </v-subheader>

    <v-card>
      <v-card-title class="primary white--text d-flex justify-space-between">
        <span>Pesquisar</span>
      </v-card-title>
      <v-card-text class="pt-7 px-7">
        <v-row dense>
          <v-col cols="12">
            <span class="primary--text">Viagem</span>
            <v-select v-model="viagemId" :items="viagens" item-text="nome" item-value="id"
              placeholder="Selecione a viagem" class="mt-3 custom-solo-selection" solo no-data-text="Sem viagem" />
          </v-col>
          <v-col cols="12" md="6">
            <span class="primary--text">Tipo de venda</span>
            <v-select v-model="tipoVendaIds" :items="tipoVenda" item-text="descricao" item-value="id"
              placeholder="Selecione o tipo de venda" class="mt-3 custom-solo-selection" solo
              no-data-text="Sem tipo de venda" multiple />
          </v-col>
          <v-col cols="12" md="3">
            <span class="primary--text">Data inicial</span>
            <Calendar @dateValue="[(dataInicial = $event)]" />
          </v-col>
          <v-col cols="12" md="3">
            <span class="primary--text">Data final</span>
            <Calendar @dateValue="[(dataFinal = $event)]" />
          </v-col>
          <v-col cols="12" md="3">
            <span class="primary--text">Hora inicial</span>
            <v-text-field v-model="horaInicial" type="time" placeholder="Selecione a hora inicial"
              class="mt-3 custom-solo-selection" solo />
          </v-col>
          <v-col cols="12" md="3">
            <span class="primary--text">Hora final</span>
            <v-text-field v-model="horaFinal" type="time" placeholder="Selecione a hora final"
              class="mt-3 custom-solo-selection" solo />
          </v-col>
        </v-row>
        <v-row dense class="d-flex justify-end">
          <v-btn dark depressed color="blue" class="mr-3" @click="printItem()" v-if="data">
            IMPRIMIR
          </v-btn>
          <v-btn dark depressed color="green" class="mr-3" @click="search()">
            FILTRAR
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <div v-if="data">
      <v-card class="mt-5" v-for="key in Object.keys(data)" :key="key">
        <v-card-title class="d-flex justify-center">
          <span>{{ converteData(key) }} </span>
        </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="black--text">PASSAGEIRO</th>
                  <th class="black--text">IDENTIFICADOR</th>
                  <th class="black--text">ORIGEM</th>
                  <th class="black--text">DESTINO</th>
                  <th class="black--text">HORÁRIO</th>
                  <th class="black--text">TIPO DE VENDA</th>
                  <th class="black--text">VALOR</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in data[key]" :key="item.id">
                  <td>{{ item.passageiro }}</td>
                  <td>{{ item.cod_barra }}</td>
                  <td>{{ item.origem }}</td>
                  <td>{{ item.destino }}</td>
                  <td>{{ formatTime(item.data) }}</td>
                  <td>{{ item.tipo }}</td>
                  <td class="text-right black--text">
                    R$ {{ valorBrasileiro(item.valor_total) }}
                  </td>
                </tr>
                <!-- Linha de total -->
                <tr>
                  <td colspan="6" class="text-right black--text"><strong>Total:</strong></td>
                  <td class="text-right black--text">
                    <strong>R$ {{ valorBrasileiro(getTotal(data[key])) }}</strong>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </div>

    <div style="width: 100%" id="printReport" class="d-none">
      <header style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
        ">
        <img src="@/assets/logoBlue.png" alt="[logo]" style="width: 600px; padding: 20px" />
        <div>
          <h5>
            {{ `Passagens Vendidas` }}
          </h5>
          <p>
            {{ viagemNome }}
            <br />
            {{ convertDate(dataInicial) }} - {{ convertDate(dataFinal) }}
          </p>
        </div>
      </header>

      <div v-if="data">
        <table v-for="key in Object.keys(data)" :key="key" style="text-align: center; width: 100%; margin-top: 40px">
          <thead>
            <tr style="text-align: center; color: #363636">
              <th colspan="6" style="
                  border-bottom: 1px solid rgb(214, 214, 214);
                  padding: 5px;
                ">
                {{ convertDateTimeNew(key) }}
              </th>
            </tr>
            <tr style="text-align: center; color: #363636">
              <th style="
                  border-bottom: 1px solid rgb(214, 214, 214);
                  padding: 5px;
                ">
                PASSAGEIRO
              </th>
              <th style="
                  border-bottom: 1px solid rgb(214, 214, 214);
                  padding: 5px;
                ">
                IDENTIFICADOR
              </th>
              <th style="
                  border-bottom: 1px solid rgb(214, 214, 214);
                  padding: 5px;
                ">
                ORIGEM
              </th>
              <th style="
                  border-bottom: 1px solid rgb(214, 214, 214);
                  padding: 5px;
                ">
                DESTINO
              </th>
              <th style="
                  border-bottom: 1px solid rgb(214, 214, 214);
                  padding: 5px;
                ">
                TIPO DE VENDA
              </th>
              <th style="
                  border-bottom: 1px solid rgb(214, 214, 214);
                  padding: 5px;
                ">
                VALOR
              </th>
            </tr>
          </thead>
          <tbody v-for="item in data[key]" :key="item.id">
            <tr style="border-bottom: 1px solid rgb(214, 214, 214)">
              <td style="padding: 5px">
                {{ item.passageiro }}
              </td>
              <td style="padding: 5px">
                {{ item.cod_barra }}
              </td>
              <td style="padding: 5px">
                {{ item.origem }}
              </td>
              <td style="padding: 5px">
                {{ item.destino }}
              </td>
              <td style="padding: 5px">
                {{ item.tipo }}
              </td>
              <td style="padding: 5px" class="text-right">
                R$ {{ valorBrasileiro(item.valor_total) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-container>
</template>

<script>
import BackArrow from "../../components/BackArrow/index.vue";
import Helpers from "@/mixins";
import Calendar from "@/components/Calendars.vue";
export default {
  mixins: [Helpers],
  components: {
    BackArrow,
    Calendar,
  },
  data: () => ({
    viagens: [],
    tipoVenda: [],
    dataInicial: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dataFinal: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    horaInicial: "",
    horaFinal: "",
    viagemId: null,
    tipoVendaIds: [],
    data: null,
  }),
  created() {
    this.Helpers;
    this.loadData();
  },
  methods: {
    async loadData() {
      this.$store.dispatch("activeLoader", true);
      const data = await Promise.all([
        this.$http.get(`/viagens`).then((res) => res.data),
        this.$http.get("/tiposvenda").then((res) => res.data),
      ]);
      this.viagens = data[0].map((item) => ({
        id: item.id,
        nome: `${item.descricao} - ${item.horario}${item.extra ? " - EXTRA" : ""
          }`,
      }));
      this.tipoVenda = data[1];
      this.$store.dispatch("activeLoader", false);
    },
    async search() {
      if (
        this.viagemId &&
        this.tipoVendaIds.length &&
        this.dataInicial &&
        this.dataFinal
      ) {
        this.$store.dispatch("activeLoader", true);
        const { data } = await this.$http.post("/viagens/vendas", {
          viagemId: this.viagemId,
          tipoVendaIds: this.tipoVendaIds.join(", "),
          dataInicial: this.dataInicial,
          dataFinal: this.dataFinal,
          horaInicial: this.horaInicial || undefined,
          horaFinal: this.horaFinal || undefined,
        });
        this.data = data.reduce(function (r, a) {
          r[a.data] = r[a.data] || [];
          r[a.data].push(a);
          return r;
        }, Object.create(null));
        this.$store.dispatch("activeLoader", false);
      } else {
        this.data = null;
      }
    },
    printItem() {
      this.$htmlToPaper("printReport");
    },
    getTotal(data) {
      return data.reduce((total, item) => total + parseFloat(item.valor_total), 0);
    },
    valorBrasileiro(value) {
      // Verifica se o valor não é um número, tenta convertê-lo
      if (typeof value !== 'number') {
        value = parseFloat(value);
      }

      // Verifica se ainda não é um número ou é NaN após a conversão
      if (isNaN(value)) {
        return '0,00';
      }

      return value.toFixed(2).replace('.', ',');
    },

    converteData(dateTime) {
      if (!dateTime) return '';

      const [date] = dateTime.split(' ');
      const [year, month, day] = date.split('-');

      return `${day}/${month}/${year}`;
    },
    formatTime(dateTime) {
      if (!dateTime) return '';

      const [, time] = dateTime.split(' ');
      const [hours, minutes] = time.split(':');

      return `${hours}:${minutes}`;
    },

  },
  computed: {
    viagemNome: function () {
      const item = this.viagens.find((item) => item.id == this.viagemId);
      if (item) {
        return item.nome;
      }
      return "";
    },
  },
};
</script>

<style lang="css">
.custom-solo-selection .v-input__control {
  min-height: 0 !important;
}

.custom-solo-selection .v-input__slot {
  padding: 2.5px 12px !important;
}

.custom-solo-selection .v-input__slot {
  box-shadow: none !important;
  background-color: #b3e6f8 !important;
}

.custom-solo-selection .v-text-field__details {
  margin-bottom: 0 !important;
}

.container-form {
  position: relative;
  min-height: calc(100vh - 70px);
}
</style>
